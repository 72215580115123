import React from 'react'

import * as successStyles from "./success_msg.module.scss"
import successLogo from '../../../static/assets/logos/check.png'

const SuccessMessage = () => {
    return (
        <div className={successStyles.container}>
            <div className={successStyles.wrapper}>
                <img alt="Success icon" src={successLogo}/>
                <h1>Thank you!</h1>
                <p>Your message has been sent.</p>
                <a href="/">Back to our website.</a>
            </div>
        </div>
    )
}

export default SuccessMessage